import React from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { LOCALE_DICT, REFINEMENT_FILTER_COLORS, REFINEMENT_FRAME_IMAGE_LINKS } from '@constants';
import { TypographyButton } from '@components';
import { capitalize } from '@utils/strings';
import { ALGOLIA_SEARCH_PARAMS } from '@utils/algolia';
import { buildUrl } from '@utils/urls';
import { useLocalStorage, useTranslation } from '@utils/hooks';
import Img from '../Img';
import styles from './FilterCard.module.scss';

type FilterCardProps = {
	label: string;
	showSwatch?: boolean;
	value: string;
};

const getTranslatedTexts = translator => {
	return {
		topsLabelTitle: (topLabel: string) => translator('tops-label-title', { topLabel }),
		allTops: translator('all-tops'),
		altAllTops: (label: string) => translator('alt-all-tops', { label }),
	};
};

const FilterCard = ({ label, showSwatch = true, value }: FilterCardProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const swatchColor = REFINEMENT_FILTER_COLORS[label.toUpperCase()];
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setAllTopsTitle] = useLocalStorage('allTopsTitle', translations.allTops);
	const swatchClasses = cn(styles.swatch, {
		[styles.rainbow]: label.toUpperCase() === 'RAINBOW',
	});
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;
	const refimentFrame = REFINEMENT_FRAME_IMAGE_LINKS[label.toUpperCase()];
	const searchIndex = ALGOLIA_SEARCH_PARAMS[countryCode];
	const href = showSwatch ? searchIndex.ALGOLIA_FILTER_COLOR_URL : searchIndex.ALGOLIA_FILTER_DESIGN_URL;
	const router = useRouter();
	const handleOnClick = () => {
		setAllTopsTitle(translations.topsLabelTitle(label));
		const url = buildUrl('/top-frames', { [href]: value });
		router.push(url);
	};

	if ((swatchColor && refimentFrame) || !showSwatch) {
		return (
			<div className={styles.container} onClick={handleOnClick}>
				<Img
					className={styles.richMediaImg}
					src={refimentFrame}
					alt={translations.altAllTops(label)}
					sizes='(min-width: 76.8em) 24em, 10em'
				/>
				<li key={`swatch-${label}`} className={cn(styles.swatchContainer)} title={label}>
					{showSwatch && (
						<div className={styles.wrapper}>
							<div
								className={swatchClasses}
								style={{ backgroundColor: swatchColor }}
								title={label}
								data-image-label={label}
							/>
						</div>
					)}
					<TypographyButton small className={styles['label']}>
						{capitalize(label, true)}
					</TypographyButton>
				</li>
			</div>
		);
	}
};

export default FilterCard;
