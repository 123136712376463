import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { Button, Countdown, CutPaper, Flex, Heading, Lozenge, TypographyButton } from '@components';
import { createCutPaperPath, getBadgeTypes, getCountdownCopy, useTranslation } from '@utils/index';
import { ContentfulImage } from '@ts/contentful';
import variables from '@styles/export.module.scss';
import styles from './CountdownBanner.module.scss';

type CountdownBannerProps = {
	launchDate: string;
	teaserSlug: string;
	collectionSlug: string;
	backgroundImageLayout: string;
	backgroundImageMobile: ContentfulImage;
	backgroundImageDesktop: ContentfulImage;
	heading: string;
	collectionIconImage?: string;
	className?: string;
};

const getTranslatedTexts = translator => {
	return {
		learnMore: translator('learn-more'),
		shopCollection: translator('shop-collection'),
	};
};

const CountdownBanner = ({
	heading,
	launchDate,
	teaserSlug,
	collectionSlug,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	backgroundImageMobile,
	backgroundImageDesktop,
	backgroundImageLayout,
	collectionIconImage = null,
	className,
}: CountdownBannerProps) => {
	const router = useRouter();
	const { translator, locale } = useTranslation();

	const translations = getTranslatedTexts(translator);
	const COUNTDOWN_COPY = getCountdownCopy(locale);
	const BADGE_TYPES = getBadgeTypes(locale);
	const isLaunchDateInTheFuture = launchDate && +new Date(launchDate) - +new Date() > 0;
	const badge = isLaunchDateInTheFuture ? BADGE_TYPES.SOON : BADGE_TYPES.LAUNCHED;
	const isFullBleed = backgroundImageLayout === 'Full Bleed';
	const cutPaperPath = createCutPaperPath('guava');

	const navigateToCollection = () => {
		if (!collectionSlug) return;
		return router.push(collectionSlug);
	};

	const buttons = isLaunchDateInTheFuture ? (
		<div className={styles.countdown}>
			<Countdown date={launchDate} text={isFullBleed ? null : COUNTDOWN_COPY.LAUNCH} />
			{teaserSlug && (
				<Link href={teaserSlug} style={{ display: 'block', textAlign: 'center', width: '100%', marginTop: '1.6rem' }}>
					<TypographyButton style={{ color: variables.blue2, textAlign: 'center', width: '100%' }}>
						{translations.learnMore}
					</TypographyButton>
				</Link>
			)}
		</div>
	) : (
		<Button color='white' onClick={navigateToCollection}>
			{translations.shopCollection}
		</Button>
	);

	return (
		<section className={cn(styles.section, className, { [styles['full-bleed']]: isFullBleed })}>
			<Link href={teaserSlug}>
				<Flex
					className={cn(styles.banner, {
						[styles['full-bleed']]: isFullBleed,
						[styles['just-launched']]: !isLaunchDateInTheFuture,
					})}
					justify={isFullBleed ? 'center' : 'between'}
					align='center'
					style={{ backgroundImage: `url(${backgroundImageDesktop.url})` }}
				>
					<div
						className={cn(styles['name-container'], {
							[styles['just-launched-collection']]: !isLaunchDateInTheFuture,
						})}
					>
						<Lozenge
							extraClasses={styles.lozenge}
							shape='square'
							color={badge.color}
							backgroundColor={badge.backgroundColor}
						>
							{badge.displayText}
						</Lozenge>
						{collectionIconImage ? (
							<div className={styles.icon}>
								<img src={`${collectionIconImage}`} alt='collection logo' />
							</div>
						) : (
							<div className={styles.heading}>
								<CutPaper padding='0' cutPaperPath={cutPaperPath}>
									<Heading tag='h6'>{heading}</Heading>
								</CutPaper>
							</div>
						)}
					</div>
					{buttons}
				</Flex>
			</Link>
		</section>
	);
};

export default CountdownBanner;
